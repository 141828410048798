<template>
    <div class="position-relative">
        <!-- <page-title></page-title> -->
        <b-row>
            <b-col cols="10">
                <div class="rounded overflow-hidden">
                    <b-carousel id="carousel-fade" controls fade indicators>
                        <b-carousel-slide
                            v-for="(image, index) in projectInfo.images"
                            :key="'carousel_' + index"
                            :style="'background: url(' + image + ');'"
                            style="
                                background-size: cover;
                                background-position: center center;
                                width: 100%;
                                height: calc(100vh - 8rem);
                            "
                        >
                        </b-carousel-slide>
                    </b-carousel>
                </div>

                <!-- <b-carousel id="carousel-fade" :interval="5000">
                    <b-carousel-slide
                        v-for="(image, index) in projectInfo.images"
                        :key="index"
                        class="h-100"
                    >
                        <template v-slot:img>
                            <img
                                :src="image"
                                alt="项目图片"
                                class="d-block img-fluid w-100"
                                style="height: calc(100vh - 30rem) !important"
                            />
                        </template>
                    </b-carousel-slide>
                </b-carousel> -->
            </b-col>

            <b-col cols="2">
                <b-card style="height: calc(100vh - 8rem)">
                    <b-card-title class="font-weight-bolder">
                        {{ projectInfo.project_name }}
                    </b-card-title>
                    <b-card-sub-title
                        v-if="projectInfo.project_en_name"
                        class="mb-1"
                        >{{ projectInfo.project_en_name }}</b-card-sub-title
                    >

                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div class="d-flex align-items-center">
                            <b-avatar
                                rounded
                                variant="light-success"
                                size="30"
                                class="mr-1"
                            >
                                <b-icon-rulers
                                    variant="success"
                                    scale="1.5"
                                ></b-icon-rulers>
                            </b-avatar>
                            <div>面积</div>
                        </div>
                        <div>
                            <span class="text-success font-weight-bolder">
                                {{ projectInfo.building_area }}
                            </span>
                            <small>m<sup>2</sup></small>
                        </div>
                    </div>

                    <div
                        class="d-flex align-items-center justify-content-between mt-1"
                    >
                        <div class="d-flex align-items-center">
                            <b-avatar
                                rounded
                                variant="light-warning"
                                size="30"
                                class="mr-1"
                            >
                                <b-icon-layers-fill
                                    variant="warning"
                                    scale="1.5"
                                ></b-icon-layers-fill>
                            </b-avatar>
                            <div>楼层</div>
                        </div>
                        <div>
                            <span class="text-warning font-weight-bolder">
                                {{ projectInfo.building_level }}
                            </span>
                        </div>
                    </div>

                    <div
                        class="d-flex align-items-center justify-content-between mt-1"
                    >
                        <div class="d-flex align-items-center">
                            <b-avatar
                                rounded
                                variant="light-info"
                                size="30"
                                class="mr-1"
                            >
                                <b-icon-building
                                    variant="info"
                                    scale="1.5"
                                ></b-icon-building>
                            </b-avatar>
                            <div>类型</div>
                        </div>
                        <div>
                            <span class="text-info font-weight-bolder">
                                {{ projectInfo.building_type }}
                            </span>
                        </div>
                    </div>

                    <div
                        class="d-flex align-items-center justify-content-between mt-1"
                    >
                        <div class="d-flex align-items-center">
                            <b-avatar
                                rounded
                                variant="light-primary"
                                size="30"
                                class="mr-1"
                            >
                                <b-icon-calendar-date-fill
                                    variant="primary"
                                    scale="1.5"
                                ></b-icon-calendar-date-fill>
                            </b-avatar>
                            <div>建成时间</div>
                        </div>
                        <div>
                            <span class="text-primary font-weight-bolder">
                                {{ projectInfo.building_time }}
                            </span>
                        </div>
                    </div>

                    <div
                        class="d-flex align-items-center justify-content-between mt-1"
                    >
                        <div class="d-flex align-items-center">
                            <b-avatar
                                rounded
                                variant="light-danger"
                                size="30"
                                class="mr-1"
                            >
                                <b-icon-lightning-fill
                                    variant="danger"
                                    scale="1.5"
                                ></b-icon-lightning-fill>
                            </b-avatar>
                            <div>今日用电</div>
                        </div>
                        <div>
                            <span
                                class="text-danger font-weight-bolder mr-50"
                                >{{
                                    projectInfo.energy_d
                                        ? projectInfo.energy_d
                                        : "N/A"
                                }}</span
                            >
                            <small>kWh</small>
                        </div>
                    </div>

                    <div class="divider">
                        <div class="divider-text">简介</div>
                    </div>

                    <b-card-text class="mt-1">
                        {{ projectInfo.description }}
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { routerParams } from "@/libs/utils/routerParams";
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
// import PageTitle from "@/views/layout/PageTitle";

export default {
    name: "ProjectDetailSimple",
    components: {
        // PageTitle,
    },
    setup() {
        const event = reactive({
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            projectInfo: {
                images: [],
            },
        });
        const getProjectInfo = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchProject", project_id)
                .then((response) => {
                    event.projectInfo = response.data;
                    console.log("event.projectInfo", event.projectInfo);
                })
                .catch((error) => {
                    showToast(error);
                });
        };
        onMounted(() => {
            getProjectInfo();
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>